import { Button, Col, Image, Card, Table, Tabs, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ShowAttrData, dateString } from "../../helper/functions";
import lang from "../../helper/langHelper";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import { useNavigate, useParams } from "react-router";
import Loader from "../../components/Loader";
import { AppStateContext, useAppContext } from "../../context/AppContext";
import ReplyQuote from "./ReplyQuote";

const ViewRequested = ({}) => {
  const { request } = useRequest();
  const [quote, setQuote] = useState([]);
  const [replyQuote, setReplyQuote] = useState([]);
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState("");
  const [loading, setLoading] = useState(false);
  const { setPageHeading } = useContext(AppStateContext);
  const {language} = useAppContext()
  const navigate = useNavigate();

  const params = useParams();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const getData = (id) => {
    setLoading(true);
    request({
      url: `${apiPath.listQuote}/view/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);
        if (!status) return;
        setQuote(data.quote);
        setReplyQuote(data.reply);
      },
      onError: (err) => {
        setLoading(false);
        console.log(err, " : error : ");
      },
    });
  };

  useEffect(() => {
    if (!params.id) return;
    setLoading(true);
    getData(params.id);
  }, [params.id]);

  useEffect(() => {
    setPageHeading(lang("Quote  Management"));
  }, []);

  return (
    <>
      <div className="tab_inner_tit">
        <div className="tab-upload-wrap d-flex align-items-center justify-content-between"></div>
      </div>

      <div className="table-responsive customPagination withOutSearilNo">
        {loading ? (
          <Loader />
        ) : (
          <Card className="quote-card" title={lang("View Details")}>
         <div className="view-main-list">
             {quote?.user_id?.name ? <div className="label-quote-card view-inner-cls">
              <h5>{lang("Customer Name")} :</h5>
              <h6 className="view-quote-text">
                { quote?.user_id?.name }
              </h6>
            </div> : "" }
           {quote?.service_id?.service_id?.name ? <div className="label-quote-card view-inner-cls">
              <h5>{lang("Service Name")} :</h5>
              <h6 className="view-quote-text">
                
            
                { language !== "en" && language !== null
                            ? quote?.service_id?.service_id?.[`${language}_name`] ?? quote?.service_id?.service_id?.name
                            : quote?.service_id?.service_id?.name }
              </h6>
            </div> : ""}
             {quote?.package_id?.name ? <div className="label-quote-card view-inner-cls">
               <h5>{lang("Package Name")} :</h5>
               <h6 className="view-quote-text">               
                 {quote?.package_id?.name}
               </h6>
             </div> : ""}
            <div className="label-quote-card view-inner-cls">
              <h5> {lang("Price")} :</h5>
              <h6 className="view-quote-text">
                
                {quote?.price ?? "0"}
              </h6>
            </div> 
           
            {quote?.description ? <div className="label-quote-card view-inner-cls">
              
              <h5>{lang("Description")} :</h5>
              <h6 className="view-quote-text">
                {quote?.description}
              </h6>
            </div> : ""}
            {quote?.values?.length && quote?.values?.length > 0 ? (
              <div className="label-quote-card view-inner-cls attributes-last">
                <h5>{lang("Attributes")} :</h5>
                <ShowAttrData attributes={quote?.values}/>
                
              </div>
            ) : (
              ""
            )}

         </div>

            {quote?.image ? (
              <div className="quote-img">
                {" "}
                <Image src={quote?.image} />
              </div>
            ) : (
              ""
            )}

            <div className="quote-replay-img">
              {replyQuote ? (
                <div className="quote-bottam-headding view-main-list">
                  <h4>{lang("Your Reply Quote")}</h4>
                   <div className="label-quote-card view-inner-cls">
                    <h5>Price :{" "}</h5>
                    <h6 className="view-quote-text">
                      {" "}
                      {replyQuote?.price ? replyQuote?.price : "0"}
                    </h6>
                  </div> 
                 {replyQuote?.message ? <div className="label-quote-card view-inner-cls">
                   <h5> {lang("Message")} :{" "}</h5>
                    <h6 className="view-quote-text">
                      {" "}
                      {replyQuote?.message}
                    </h6>
                  </div> : "" }
                  {replyQuote?.images ? (
                    <div className="quote-img">
                      <Image src={replyQuote?.images?.[0]} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <Button
                  className="ms-3 primary_btn btnStyle --mb4"
                  onClick={() => {
                    setShow(true);
                    setSelected(quote);
                  }}
                >
                  {lang("Reply Quote")}
                </Button>
              )}
            </div>
          </Card>
        )}
      </div>
      {
        <ReplyQuote
          show={show}
          hide={() => {
            setShow(false);
            setSelected("");
          }}
          data={selected}
          refresh={() => navigate("/quote")}
        />
      }
    </>
  );
};

export default ViewRequested;
