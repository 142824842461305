import {
  Button,
  Card,
  Col,
  Input,
  Modal,
  Row,
  Table,
  Tabs,
  Tooltip,
  Select,
  DatePicker,
  Badge
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import apiPath from "../../constants/apiPath";
import { AppStateContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import { useNavigate, useParams } from "react-router";
import { dateString } from "../../helper/functions";
import SectionWrapper from "../../components/SectionWrapper";
import useDebounce from "../../hooks/useDebounce";
import { UndoOutlined } from "@ant-design/icons";
import moment from "moment";
import { useAuthContext } from "../../context/AuthContext";





function Index() {
  const { setPageHeading } = useContext(AppStateContext);
  const [searchText, setSearchText] = useState("");

  const { request } = useRequest();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 300);
  const [refresh, setRefresh] = useState(false);
  const [services, setServices] = useState([]);
  const urlParams = new URLSearchParams(window.location.search);
  const customDate = urlParams.get("custom_date");
  const serviceId = urlParams.get("service_id");
  const packageId = urlParams.get("package_id");
  const startDate = urlParams.get("start_date");
  const endDate = urlParams.get("end_date");
  const page = urlParams.get("page");
  const pageSize = urlParams.get("pageSize");
  const {  selectedCategory } = useAuthContext();
  const {language} = useAuthContext()


  const navigate = useNavigate()
  const [pagination, setPagination] = useState({
    current: +page ? +page : 1, pageSize: +pageSize ? +pageSize : 10,
    total: 0,
  });

  const [filter, setFilter] = useState({
    start_date: startDate ? startDate : (customDate ? customDate : undefined),
    end_date:   endDate ? endDate :   (customDate ? customDate : undefined),
    service_id: serviceId ?? undefined,

  });



  const columns = [
    {
      title: lang("quote ID"),
      dataIndex: "quoteId",
      render: (_, { quoteId }) =>
        `#${quoteId ? quoteId : ""}`,
    },
    {
      title: lang("Service"),
      dataIndex: "service_id",
      key: "service_id",
      sorter: (a, b) => a?.service_id?.service_id?.name.localeCompare(b.service_id?.service_id?.name),
      sortDirections: ["ascend", "descend"],
      render: (_, { service_id }) => `${service_id?.service_id?.name ? service_id?.service_id?.name : ""}`,
    },
    {
      title: lang("Customer name"),
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a?.user_id?.name.localeCompare(b.user_id?.name),
      sortDirections: ["ascend", "descend"],
      render: (_, { user_id }) => `${user_id?.name}`,
    },
    {
      title: lang("Price"),
      dataIndex: "price",
      key: "price",
      render: (_, { price }) => price,
    },
    // {
    //   title: lang("Quote status"),
    //   key: "action",
    //   render: (_, { status }) => {
    //     let statusColor = "yellow";
    //     let text = lang("request");
    //     if (status === "request") {
    //       statusColor = "cyan";
    //       text = lang("requested");
    //     } else if (status === "received") {
    //       statusColor = "cyan";
    //       text = lang("Received");
    //     } else if (status === "complete") {
    //       statusColor = "lime";
    //       text = lang("Complete");
    //     } else if (status === "fulfill") {
    //       statusColor = "lime";
    //       text = lang("Fulfill");
    //     }
    //     return (
    //       <Badge
    //         className="cap"
    //         status={statusColor ? statusColor : "default"}
    //         text={text}
    //       />
    //     );
    //   },
    // },
    {
      title: lang("Have Reply"),
      dataIndex: "have_reply",
      key: "have_reply",
      render: (_, { have_reply }) => `${have_reply === true ? lang("Yes") : ("No")}`,
    },

    {
      title: lang("Requested On"),
      dataIndex: "created_at",
      key: "created_at",
      render: (_, { created_at }) => `${dateString(created_at, "lll")}`,
    },
    {
      title: lang("Action"),
      fixed: "right",
      render: (_, record) => {
        return (
          <>
            <Tooltip
              title={lang("View Detail")}
              color={"purple"}
              key={"viewDetail"}
            >
              <Button className="ms-sm-2 mt-xs-2 btnStyle btnOutlineDelete" onClick={() => navigate(`/quote/${record?._id}`)}>
                {lang("View Detail")}
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const fetchData = (pagination, filters, sorter) => {
    setLoading(true);
    const filterActive = filters ? filters.is_active : null;
    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

      if (queryString) {
        queryString.split('&').forEach(param => {
          const [key, value] = param.split('=');
          urlParams.set(key, value);
        });
      } 
      navigate(
        `/quote${queryString ?
          `?${queryString}&search=${encodeURIComponent(debouncedSearchText)}&page=${encodeURIComponent(pagination.current ?? 1)}&pageSize=${encodeURIComponent(pagination.pageSize ?? 10)}`  
        : `?search=${encodeURIComponent(debouncedSearchText)}&page=${encodeURIComponent(pagination.current ?? 1)}&pageSize=${encodeURIComponent(pagination.pageSize ?? 10)}`}`
      );

    request({
      url: apiPath.listQuote +
        `?status=${filterActive ? filterActive.join(",") : ""}&type=service&page=${pagination && pagination?.current ? pagination.current : 1
        }&limit=${pagination && pagination?.pageSize ? pagination.pageSize : 10
        }&search=${debouncedSearchText}${queryString ? `&${queryString} ` : ""
        }`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);
        if (!status) return;
        setList(data?.data || [])
        setPagination((prev) => ({
          current: pagination.current,
          total: data.data.totalDocs,
        }));
      },
      onError: (err) => {
        setLoading(false);
        console.log(err, " : error : ")
      }
    })
  }


  const getFilter = () => {
    request({
      url: `${apiPath.listQuote}/filters`,
      method: "GET",
      onSuccess: ({ data }) => {
        setServices(data?.services);
      },
    });
  };

  const handleChange = (pagination, filters, sorter) => {
    const { field, order } = sorter;
    let query = undefined;
    if (field && order) {
      query = `${field}=${order}`;
      console.log(query);
    }
    fetchData(pagination, filters, query);
  };


  const onSearch = (e) => {
    setSearchText(e.target.value);
    setPagination({ current: 1 ,pageSize : pagination.pageSize});
  };


  const onChange = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    fetchData(pagination)
    getFilter();
  }, [filter, refresh, debouncedSearchText,selectedCategory]);


  useEffect(() => {
    setPageHeading(lang("Quote  Management"));
  }, []);



  return (
    <>


      <SectionWrapper
        cardHeading={lang(`All Quotes`)}
        extra={
          <>

            <div className="w-100 text-head_right_cont">
              <div className="pageHeadingSearch">
                <Input.Search
                  value={searchText}
                  className="searchInput"
                  placeholder={lang("Search by cutomer  name")}
                  onChange={onSearch}
                  allowClear
                />
              </div>
              <div className="role-wrap">
                <DatePicker.RangePicker
                 disabledDate={(current)=> {return current && current > moment().endOf("day")}}
                  value={[
                    filter.start_date
                      ? moment(filter.start_date)
                      : undefined,
                    filter.end_date
                      ? moment(filter.end_date)
                      : undefined,
                  ]}
                  onChange={(value) => {
                    if (value) {
                      setFilter((prev) => ({
                        ...prev,
                        start_date: moment(value[0]).format(
                          "YYYY-MM-DD"
                        ),
                        end_date: moment(value[1]).format("YYYY-MM-DD"),
                      }));
                    } else {
                      setFilter((prev) => ({
                        ...prev,
                        start_date: undefined,
                        end_date: undefined,
                      }));
                    }
                  }}
                />
              </div>
              <div className="role-wrap">
                <Select
                  last20Years
                  width="110px"
                  placeholder={lang("Service")}
                  showSearch
                  value={filter.service_id}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={services.map((item) => ({
                    value: item._id,                
                    label: `${language !== "en" && language !== null
                    ? item?.service_id?.[`${language}_name`] ?? item?.service_id?.name
                    : item?.service_id?.name} (${item?.discount_price ?? item?.original_price})`,
                  }))}
                  onChange={(value) => onChange("service_id", value)}
                />
              </div>

              <Button
                onClick={() => {
                  setFilter({

                    service_id: undefined,

                  });
                  setSearchText("");
                  setPagination((prev)=>{
                    return {
                      ...prev,
                      current: 1,
                      pageSize: 10,
                    }
                  })
                }}
                type="primary"
                icon={<UndoOutlined />}
              >
                {lang("Reset")}
              </Button>

            </div>
          </>
        }
      >
        <div className="table-responsive customPagination withOutSearilNo">
          <Table
            loading={loading}
            columns={columns}
            dataSource={list}
            pagination={{
              defaultPageSize: +pageSize ? +pageSize : +pagination.pageSize,
              responsive: true,
              total: pagination.total,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>
      </SectionWrapper>
    </>
  );
}

export default Index;
