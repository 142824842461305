import {
  Button,
  Card,
  Col,
  DatePicker,
  Row,
  Select,
  Tabs,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import apiPath from "../../constants/apiPath";
import { AppStateContext, useAppContext } from "../../context/AppContext";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import { useNavigate } from "react-router";
import { useAuthContext } from "../../context/AuthContext";
import { formatPhone } from "../../helper/functions";
import lang from "../../helper/langHelper";
import SectionWrapper from "../../components/SectionWrapper";

import Ratings from "./Rating";
import moment from "moment";
import { UndoOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;
const { Title, Text } = Typography;

const years = [2024];

function Home() {
  const { setPageHeading, country } = useContext(AppStateContext);

  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState(years[0]);
  const [filter, setFilter] = useState({
    key: "life_time",
    custom_date: undefined,
  });
  const { userProfile, isAdmin, setRefreshUserProfile } = useAuthContext();
  const {language} = useAppContext()
  const [dashboard, setDashboard] = useState();
  const [ratings, setRatings] = useState([]);
  const {  selectedCategory } = useAuthContext();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 3,
    total: 0,
  });

  const navigate = useNavigate();

  const count = [
    {
      today: lang("Profile Views"),
      title: `${dashboard && dashboard.views ? dashboard.views : 0}`,

      icon: <i class="fas fa-people-carry"></i>,
      bnb: "bnb2",
      // url: `/service-provider`,
      key: "activeCustomer",
      _7Day: dashboard && (dashboard.views ?? 0),
      _14Day: dashboard && (dashboard.views ?? 0),
    },

    {
      today: lang("Profile Searches"),
      title: `${dashboard && dashboard.searchCount ? dashboard.searchCount : 0}`,
      icon: <i class="fas fa-user-tie"></i>,
      bnb: "bnb2",
      // url: "/event-type",
      key: "totalOrder",
      _7Day: dashboard && (dashboard.searchCount ?? 0),
      _14Day: dashboard && (dashboard.searchCount ?? 0),
    },
    {
      today: lang("Call Count"),
      title: `${
        dashboard && dashboard?.callCount ? dashboard?.callCount : 0
      }`,
      icon: <i class="fas fa-copyright"></i>,
      bnb: "bnb2",
      // url: "/service",
      key: "totalDriver",
     
    },
    {
      today: lang("Quotation Received"),
      title: `${
        dashboard && dashboard.quotes_received ? dashboard.quotes_received : 0
      }`,
      icon: <i class="fas fa-copyright"></i>,
      bnb: "bnb2",
      url: `/quote?custom_date=${filter.custom_date ? filter.custom_date : "" }`,
      key: "totalDriver",
      _7Day: dashboard && (dashboard.quotes_received ?? 0),
      _14Day: dashboard && (dashboard.quotes_received ?? 0),
    },
  
   
    {
      today: lang("Number Of Services"),
      title: `${
        dashboard && dashboard?.totalServices ? dashboard?.totalServices : 0
      }`,
      icon: <i class="fas fa-copyright"></i>,
      bnb: "bnb2",
      url: "/service",
      key: "totalDriver",
     
    },
    {
      today: lang("Number Of Packages"),
      title: `${
        dashboard && dashboard?.totalPackages ? dashboard?.totalPackages : 0
      }`,
      icon: <i class="fas fa-copyright"></i>,
      bnb: "bnb2",
      url: "/package",
      key: "totalDriver",
     
    },
    {
      today: lang("Number Of Reports"),
      title: `${
        dashboard && dashboard?.totalReports ? dashboard?.totalReports : 0
      }`,
      icon: <i class="fas fa-copyright"></i>,
      bnb: "bnb2",
      url: "/reports",
      key: "totalDriver",
     
    },
    {
      today: lang("Number of time added to wishlist"),
      title: `${
        dashboard && dashboard?.wishlist_count ? dashboard?.wishlist_count : 0
      }`,
      icon: <i class="fas fa-copyright"></i>,
      bnb: "bnb2",
      // url: "/reports",
      key: "totalDriver",
     
    },
    // {
    //   today: lang("Number of time added to budget"),
    //   title: `${
    //     dashboard && dashboard?.budget_count ? dashboard?.budget_count : 0
    //   }`,
    //   icon: <i class="fas fa-copyright"></i>,
    //   bnb: "bnb2",
    //   // url: "/reports",
    //   key: "totalDriver",
     
    // },
  
  ];

  useEffect(() => {
    if (!userProfile) return;

    setPageHeading(`${lang("Welcome")}  ${ language !== "en" && language !== null
    ? userProfile?.[`${language}_name`] ?? userProfile?.name
    : userProfile?.name }`);

    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    setLoading(true);
    request({
      url: apiPath.dashboard + `?${queryString ? `&${queryString}` : ""}`,
      method: "GET",
      onSuccess: ({ data }) => {
        setLoading(false);
        setDashboard(data.data);
        setRatings(data.ratings);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  }, [year, filter, country.country_id, userProfile,selectedCategory]);

  const onChange = (key, value) => {
console.log(key,"key...")
   if(key === "custom_date"){
    setFilter((prev) => ({ ...prev, [key]: value ,  "key" : "life_time" }));
   } else if(key === "key"){ 
    setFilter((prev) => ({ ...prev, [key]: value,  custom_date : undefined }));
   }else {
    setFilter((prev) => ({ ...prev, [key]: value }));
   }
   
    
  };

  return (
    <>
      <div className="page-top-space home-card layout-content">
        <div className="mb-24">
          <div className="sub_title sub_title-main-456">
            <p>{lang("Here is the information about all your business")}</p>
         <div className="main-f-dashbod-43">
        <div className="bussiness_yearmain-09">
        <div className="bussiness_year">
              <span>{lang("Filter")}</span>
              <Select
                value={filter.key}
                style={{ width: 120 }}
                onChange={(value) => {onChange("key", value);
                
                }}
                className="main-option-hh5"
                options={[
                  { value: "today", label: lang("Today") },
                  { value: "yesterday", label: lang("Yesterday") },
                  { value: "last_month", label: lang("Last Month") },
                  { value: "last_year", label: lang("Last Year") },
                  { value: "life_time", label: lang("Life Time") },
                ]}
              />
            </div>
            <div className="bussiness_year">
              <DatePicker
              disabledDate={(current)=> { return current && current > moment().endOf("day")}}
              allowClear={false}
                style={{ width: 120 }}
                value={
                  filter.custom_date ? moment(filter.custom_date) : undefined
                }
                placeholder={lang("Custom Date")}
                 onChange={(value) =>
                  {onChange("custom_date", moment(value).format("YYYY-MM-DD"))}
                }
              />
            </div>
        </div>
            <Button
              onClick={() => {
                setFilter({
                  custom_date: undefined,
                  key: "life_time",
                });
              }}
              type="primary"
              icon={<UndoOutlined />}
            >
              {lang("Reset")}
            </Button>

         </div>
          </div>

          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={24}>
              <Row
                className="rowgap-vbox"
                gutter={[24, 16]}
                // style={{ marginLeft: "0" }}
              >
                {count.map((c, index) => (
                  <Col
                    key={index}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={8}
                    className="mb-24"
                  >
                    <CountCard c={c} key={index} loading={loading} />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>

          <Row gutter={[24, 24]}>
            <Col xs={24} xl={24} className="sm-padding-0 ">
              <SectionWrapper
                cardHeading={lang("Last Rating & Reviews")}
                extra={
                  <>
                    <div className="w-100 text-head_right_cont">
                      {(filter?.key === "life_time" && ratings?.length > 0) ? <Button
                        onClick={() => navigate(`/ratings?custom_date=${filter.custom_date ? filter.custom_date : "" }`)}
                        className="ms-3 primary_btn btnStyle"
                      >
                        {lang("View All")}
                      </Button> : ""}
                    </div>
                  </>
                }
              >
                <Ratings data={ratings} loading={loading} />
              </SectionWrapper>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

const CountCard = ({ c, loading }) => {
  const [percentage, setPercentage] = useState();
  const [difference, setDifference] = useState();
  const { userProfile } = useAuthContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (!c) return null;

    console.log(c);
    const diff = c._7Day - c._14Day;

    const percentage = parseInt(
      ((diff / (c._7Day + c._14Day)) * 100).toFixed(2)
    );

    setPercentage(!!percentage ? percentage : 0);
  }, [c]);

  //if (!c) return null
  return (
    <Card
      hoverable
      bordered={false}
      className="criclebox"
      style={{ height: "100%" }}
      loading={loading}
      onClick={() => {
        navigate(c.url);
      }}
    >
      <div className="number">
        <Row align="middle" gutter={[24, 0]}>
          <Col xs={18}>
            <span>{lang(c?.today)}</span>
            {/* <p className="ftp_text">{lang("Last 7 days")}</p> */}
            <Title level={3}>{lang(c?.title)}</Title>
          </Col>
          {/* <Col xs={6}>
            <div className="icon_box">
              <LineChartWithoutAxis
                isUp={percentage >= 0}
                points={[c?._14Day, c?._7Day]}
              />
            </div>
          </Col> */}
        </Row>
      </div>
    </Card>
  );
};

export default Home;
