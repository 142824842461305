import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Rate,
  Row,
  Select,
  Spin,
} from "antd";
import React, { useEffect, useState, useContext } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import apiPath from "../../constants/apiPath";
import { lang } from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import { LoadingOutlined } from "@ant-design/icons";
import { AppStateContext } from "../../context/AppContext";
import Prouser from "../../assets/images/user.png";




function Index() {

 

  const { setPageHeading } = useContext(AppStateContext);
  const sectionName = "Ratings And Reviews";
  const { request } = useRequest();
  const [hasMore, setHasMore] = useState(true);
  const [reviewList, setReviewList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [commentModalVisible, setCommentModalVisible] = useState(false);
  const [selectedReview, setSelectedReview] = useState();
  const [toggle, setToggle] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [view, setView] = useState({});
  const [page, setPage] = useState(1);

  const urlParams = new URLSearchParams(window.location.search);
  const customDate = urlParams.get("custom_date");
  const [filter,setFilter] = useState({
    start_date : customDate ? customDate :  undefined,
    end_date : customDate ? customDate :  undefined,
    rate : "all"
  })

  const heading = lang("Reviews") + " " + lang("Management");

  const fetchData = (page) => {

    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    request({
      url: `${apiPath.review}?page=${page}&pageSize=100${
        queryString ? `&${queryString} ` : ""
      }`,
      method: "GET",
      onSuccess: ({ data, status, total }) => {
        setPageLoading(false);
        if (status) {
          const reviews = [...reviewList, ...data.data];
          setReviewList(data.data);
          if (data.data.length === 0) return setHasMore(false);
        }
      },
      onError: () => {
        setPageLoading(false);
      },
    });
  };

  useEffect(() => {
    setPageLoading(true);
    fetchData(page);
  }, [page,filter]);

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  const onNext = () => {
    console.log("handleLoadMore Called");
    if (hasMore && !pageLoading) {
      setPage((prev) => prev + 1);
    }
  };

  const [openReviewIndex, setOpenReviewIndex] = useState(null);

  const toggleView = (index) => {
    setOpenReviewIndex((prevIndex) =>
      prevIndex === index ? !(prevIndex === index) : index
    );
    setToggle(!toggle);
  };

  const onChange = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));  
};

  return (
    <>
      <div className="tabled quoteManagement">
        <div>
          <div className="d-flex align-items-center mb-3">
            <div className="tab-ratting-wrap tab-ratting-wrap-main-45">
              <h4>{lang("Reviews")}</h4>
              <div>
              <Select
              defaultValue="all"
                style={{
                width: 120,
                }}
                value={filter.rate}
               onChange={(value)=> onChange("rate", value)}
                  options={[
                    {
                      value: 'all',
                      label: lang('All'),
                    },
                    {
                      value: '5',
                      label: lang('Rate 5'),
                    },
                    {
                      value: '4',
                      label: lang('Rate 4'),
                    },
                    {
                      value: '3',
                      label: lang('Rate 3'),
                    },
                    {
                      value: '2',
                      label: lang('Rate 2'),
                    },
                    {
                      value: '1',
                      label: lang('Rate 1'),
                    },
                  ]}
    />
              </div>
            </div>
          </div>
        </div>
        <div id="scrollableDiv">
          {reviewList.length && reviewList.length > 0 ? (
            //  <InfiniteScroll
            //     dataLength={reviewList.length}
            //     next={onNext}
            //     hasMore={hasMore}
            //     loader={(pageLoading) && <Spin indicator={(<LoadingOutlined style={{ fontSize: 24, }} spin />)} />}
            //     scrollableTarget="scrollableDiv"
            //   >
              
            //      </InfiniteScroll>
          
            <Row gutter={[24, 24]} className="mb-3">
            {reviewList?.map((item, index) => (
              <Col span={24} md={12} key={index}>
                <Card
                  bordered={false}
                  className="criclebox tablespace criclebox-mian-901"
                >
                  <div className="ratings-card-box ratings-card-box-mian">
                    <div className="ratings-card-img review-img review-img-main-454">
                      <Image
                        src={item?.reviewer_id?.image ? item?.reviewer_id?.image : Prouser}
                        className="w-100"
                      />
                    </div>
                    <div className="ratings-card-text">
                      <div className="d-flex justify-content-between align-items-start rating-right">
                        <h4 className="mb-0">{item?.reviewer_id?.name}</h4>
                        <div className="reiting-23-main-s">
                          {" "}
                          <Rate value={1} count={1} /> {item.rating}
                        </div>
                      </div>
                      <div className="card-date-time">
                        <span className="ftp_text">
                          {moment(item?.created_at).format(
                            "MMM D, YYYY | hh:mm a"
                          )}
                        </span>
                        <p className="ftp_text ftp_text-main-33">
                          {item?.feedback}
                        </p>

                        {/* {!item.replies ||
                          (item?.replies?.length === 0 && (
                            <Button
                              className="primary_btn btnStyle"
                              onClick={() => {
                                setSelectedReview(item);
                                setCommentModalVisible(true);
                              }}
                            >
                              {lang("Reply")}{" "}
                              <i class="fa fa-light fa-paper-plane"></i>
                            </Button>
                          ))}
                        {item?.replies?.length > 0 && (
                          <Button
                            onClick={() => {
                              toggleView(index);
                            }}
                            className="ms-3 primary_btn btnStyle"
                          >
                            {lang("View")}
                          </Button>
                        )} */}
                      </div>
                    </div>
                  </div>
                  {/* 
                  {item?.replies &&
                    item.replies.map((reply, Index) => (
                      <div>
                        {index === openReviewIndex && (
                          <div className="ratings-card-box sub-reply" key={Index}>
                            <div className="ratings-card-img review-img">
                              <Image
                                src={reply?.sender_id?.image}
                                className="w-100"
                              />
                            </div>

                            <div className="ratings-card-text sub-review-text">
                              <h4 className="mb-0">{"Your Reply"}</h4>
                              <div className="card-date-time sub-review-time">
                                <span className="ftp_text">
                                  {moment(reply?.created_at).format(
                                    "MMM D, YYYY | hh:mm a",
                                  )}
                                </span>
                                <p className="ftp_text sub-ftp">
                                  {reply?.message}
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))} */}
                </Card>
              </Col>
            ))}
          </Row>
         
          ) : (
            <Card>
              <h3>{lang("No Rating And Reviews")}</h3>
            </Card>
          )}

          {commentModalVisible && (
            <CommentModal
              section={sectionName}
              show={commentModalVisible}
              hide={() => {
                setSelectedReview();
                setCommentModalVisible(false);
              }}
              data={selectedReview}
              refresh={() => setRefresh((prev) => !prev)}
            />
          )}
          {/* </Row> */}
        </div>
      </div>
    </>
  );
}
const CommentModal = ({ section, show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();

  const handleComment = (values) => {
    const payload = {
      message: values.message,
    };
    request({
      url: apiPath.review + "/reply/" + data?._id,
      method: "POST",
      data: payload,
      onSuccess: ({ status, message }) => {
        if (status) {
          ShowToast(message, Severty.SUCCESS);
          hide();
          refresh();
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    console.log(data);
  }, [data]);
  return (
    <Modal
      open={show}
      width={750}
      okText={lang("Comment")}
      cancelText={lang("Cancel")}
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
    >
      <Form
        id="create"
        form={form}
        onFinish={handleComment}
        layout="vertical"
        initialValues={{
          is_active: true,
        }}
      >
        <h4 className="modal_title_cls">{lang("Comment")}</h4>
        <Row gutter={[16, 0]}>
          <Col xl={24} xs={24} span={24}>
            <Form.Item
              name="message"
              label={lang("Your Comment")}
              rules={[
                {
                  required: true,
                  message: lang("Please enter your comment"),
                },
              ]}
            >
              <Input.TextArea
                rows={6}
                placeholder={lang("Type your comment here")}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Index;
