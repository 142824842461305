import {
  Col,
  Form,
  Image,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  InputNumber,
  DatePicker,
  Checkbox
} from "antd";
import React, { useEffect, useState ,useContext} from "react";

import notfound from "../../assets/images/not_found.png";
import SingleImageUpload from "../../components/SingleImageUpload";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import { useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { AuthContext, useAuthContext } from "../../context/AuthContext";
import MultipleImageUpload from "../../components/MultipleImageUpload";
import apiPath from "../../constants/apiPath";
import { ColorCircleComponent, handleServiceAttr } from "../../helper/functions";
import moment from "moment";

const AddForm = ({ api, show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const { language } = useAppContext();
  const [showInfoCol, setShowInfoCol] = useState(false);
  const [services, setServices] = useState([]);
  const [serviceAttributes, setserviceAttributes] = useState([]);
  const [image, setImage] = useState([]);
  const [coverImage, setCoverImage] = useState();
  const [category, setCategory] = useState(null);
  const [maxSrcImgUpload, setMaxSrcImgUpload] = useState(0);
  const [maxServiceAdd, setMaxServiceAdd] = useState(0);
  const { userProfile , selectedCategory } = useAuthContext();
  const formData = Form.useWatch([],form)
  console.log(formData,"formData")

  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/webp",
    "image/gif",
  ];



  const getServices = (id) => {
    request({
      url: `${api.services}/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setServices(data.data);
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const getAttribute = (service_id, id) => {
    request({
      url: `${apiPath.common.attributesByServiceCategory}/${category?._id ? category?._id : id}/${service_id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setserviceAttributes(data?.data);
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const onCreate = (values) => {
    setLoading(true);
    const {
      name,
      service_id,
      discount_price,
      original_price,
      cover_image,
      images,
      ...attributes
    } = values;
    // let mappedAttributes = [];
    // mappedAttributes = Object.keys(attributes)?.map((key) => {
    //   const attribute = serviceAttributes?.find((attr) => attr?.name === key);
    //   const value = attributes[key];

    //   if (attribute?.type === "button") {
    //     return {
    //       name: key,
    //       value: showInfoCol[key]?.show,
    //       type: "button",
    //       _id: attribute?._id,
    //       info: showInfoCol[key]?.info || "",
    //     };
    //   } else if (attribute?.type === "file") {
    //     return {
    //       name: key,
    //       type: "file",
    //       _id: attribute?._id,
    //       value: value?.length ? value?.[0]?.url : "",
    //     };
    //   } else if (attribute?.type === "calendar") {
    //     return {
    //       name: key,
    //       value: value ? moment(value).format("DD-MM-yyyy") : value,
    //       type: attribute?.type,
    //       _id: attribute?._id,
    //     };
    //   }
    //    else {
    //     return {
    //       name: key,
    //       value: value,
    //       type: attribute?.type,
    //       _id: attribute?._id,
    //     };
    //   }
    // });

    let attributeData = handleServiceAttr(attributes,showInfoCol,serviceAttributes) ?? []
    const payload = {
      name: name,
      service_id: service_id,
      discount_price: discount_price,
      original_price: original_price,
      attribute_id: attributeData,
    };

    if (coverImage?.length > 0) payload.cover_image = coverImage;
    if (image?.length > 0) payload.images = image;

    // return console.log(payload, "payload");
    request({
      url: `${data ? api.addEdit + "/" + data._id : api.addEdit}`,
      method: data ? "PUT" : "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const handleCoverImage = (data) => {
    data.length > 0 ? setCoverImage(data[0].url) : setCoverImage([]);
  };

  const handleImage = (value) => {
    setImage((prev) => {
      return value.map((item) => {
        return item.url;
      });
    });
  };

  useEffect(() => {
    if (!data) return;
    console.log(data);
    const formattedAttributes = data.attribute_id?.reduce((acc, attr) => {
      if (attr?.type === "button") {
        acc[attr?.name] = {
          value: attr?.value?.[0] ?? false,
          info: attr?.info || "",
        };
        setShowInfoCol((prev) => ({
          ...prev,
          [attr?.name]: {
            show: attr?.value?.[0] ?? false,
            info: attr?.info || "",
          },
        }));
      } else if (attr?.type === "package") {  // Handle package type
        attr?.value?.forEach((option) => {
          acc[option?.option] = option?.tags || [];  // Set each option's tags/values
        });
      } else if (attr?.type === "file") {  // Handle file type
        acc[attr?.name] = attr?.value?.[0] || "";
     
      }  else if (attr?.type === "calendar") {  // Handle calendar type
        acc[attr?.name] = attr?.value?.[0] ? moment(attr?.value?.[0],"DD-MM-YYYY") : "";
      } 
      else {
        acc[attr?.name] = attr?.type === "radio"   || 
                          attr?.type === "color"   ||
                          attr?.type === "textbox" || 
                          attr?.type === "boolean" || 
                          attr?.type === "input"
        ? attr?.value?.[0]
        : attr?.value;
      }
      return acc;
    }, []);

  console.log(formattedAttributes,"formattedAttributes")
  console.log(data,"data")

    form.setFieldsValue({ 
      ...data,
      ...formattedAttributes,
      service_id : data?.service_id?._id,
      images : data.images
     });
    setImage(data.images);
    setCoverImage(data.cover_image);
  }, [data,selectedCategory]);

  useEffect(() => {
     if(!userProfile)return;
    console.log(userProfile, "userProfile");
    getServices(selectedCategory?._id );
    setCategory(selectedCategory);
    setMaxSrcImgUpload(selectedCategory?.number_of_images_per_service ?? 0);
    if(data) getAttribute(data?.service_id?._id, selectedCategory?._id);
  }, [userProfile,selectedCategory]);

  const handleInfoCol = (attributeName, value) => {
    console.log(attributeName, "attributeName", value, "value");
    setShowInfoCol((prev) => ({
      ...prev,
      [attributeName]: {
        show: value,
        info: value ? prev[attributeName]?.info || "" : null,
      },
    }));
  };

  const handleServiceChange = (serviceId) => {
    setserviceAttributes([]);
    getAttribute(serviceId, category?._id);
  };

  
  const showName = (value) => {
    return language !== "en" && language !== null
      ? value?.[`${language}_name`] ?? value?.name
      : value?.name;
  };

  return (
    <Modal
      open={show}
      maskClosable={false}
      width={750}
      okText={data ? lang(`Update`) : lang(`Add`)}
      cancelText={lang(`Cancel`)}
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
    >
      <Form
        id="create"
        form={form}
        onFinish={onCreate}
        layout="vertical"
        initialValues={{
          is_active: true,
        }}
      >
        <h4 className="modal_title_cls">
          {data ? lang(`Edit Service`) : lang(`Add New Service`)}
        </h4>
        <Row gutter={[16, 0]}>
          {/* <Col span={12} sm={12}>
            <Form.Item
              label={<> {lang(`Name`)}</>}
              name="name"
              rules={[
                {
                  required: true,
                  message: lang("Name is required"),
                },
                {
                  max: 200,
                  message: lang(
                    "Name should not contain more then 200 characters!"
                  ),
                },
                {
                  min: 2,
                  message: lang("Name should contain at least 2 characters!"),
                },
                {
                  validator: (_, value) => {
                    if (/\d/.test(value)) {
                      return Promise.reject(lang("Please enter a valid name!"));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={lang(`Enter Name`)} />
            </Form.Item>
          </Col> */}
          <Col span={24} md={24}>
            <Form.Item
              name={"service_id"}
              label={lang("Service")}
              rules={[
                {
                  required: true,
                  message: lang("Please select a service"),
                },
              ]}
            >
              <Select
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder={lang("Select Services")}
                showSearch
                mode="single"
                onChange={(value) => handleServiceChange(value)}
              >
                {services?.map((service) => (
                  <Select.Option
                    key={service?._id}
                    label={showName(service)}
                    value={service?._id}
                  >
                    {language !== "en" && language !== null
                      ? service?.[`${language}_name`] ?? service?.name
                      : service?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name={"discount_price"}
              label={lang("Discount Price (JOD)")}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const originalPrice = getFieldValue("original_price");
                    const selectedService = services.find(
                      (service) => service._id === getFieldValue("service_id")
                    );
                    const minPrice = selectedService?.min_price;
                    const maxPrice = selectedService?.max_price;

                    if (value !== undefined && value !== null) {
                      if (value <= 0) {
                        return Promise.reject(
                          new Error(
                            lang("Discounted price must be a positive number")
                          )
                        );
                      }

                      if (originalPrice && value >= originalPrice) {
                        return Promise.reject(
                          new Error(
                            lang(
                              "Discounted price must be less than the original price"
                            )
                          )
                        );
                      }

                      if (minPrice !== undefined && value < minPrice) {
                        return Promise.reject(
                          new Error(
                            lang(
                              `Discounted price must be at least ${minPrice}`
                            )
                          )
                        );
                      }

                      if (maxPrice !== undefined && value > maxPrice) {
                        return Promise.reject(
                          new Error(
                            lang(
                              `Discounted price must be no more than ${maxPrice}`
                            )
                          )
                        );
                      }
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber placeholder={lang("Enter Price")} />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name={"original_price"}
              label={lang("Original Price (JOD)")}
              rules={[
                {
                  required: true,
                  message: lang("Original price is required"),
                },
                {
                  type: "number",
                  min: 0.01,
                  message: lang("Original price must be a positive number"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const discountPrice = getFieldValue("discount_price");
                    const selectedService = services.find(
                      (service) => service._id === getFieldValue("service_id")
                    );
                    const minPrice = selectedService?.min_price;
                    const maxPrice = selectedService?.max_price;

                    if (discountPrice && value < discountPrice) {
                      return Promise.reject(
                        new Error(
                          lang(
                            "Original price must be greater than the discounted price"
                          )
                        )
                      );
                    }

                    if (minPrice !== undefined && value < minPrice) {
                      return Promise.reject(
                        new Error(
                          lang(
                            `Original price must be at least JOD ${minPrice}`
                          )
                        )
                      );
                    }

                    if (maxPrice !== undefined && value > maxPrice) {
                      return Promise.reject(
                        new Error(
                          lang(
                            `Original price must be no more than JOD ${maxPrice}`
                          )
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber placeholder={lang("Enter Price")} />
            </Form.Item>
          </Col>

          <Col span={24} md={24}>
            <Form.Item
              className="upload_wrap"
              name={"cover_image"}
              label={lang("Upload Thumbnail")}
            >
              <SingleImageUpload
                value={coverImage}
                fileType={FileType}
                imageType={"coverPhoto"}
                btnName={lang("Thumbnail")}
                onChange={(data) => handleCoverImage(data)}
                isDimension={false}
                size={5}
              />
            </Form.Item>

            <div style={{ width: "100%" }} className="mt-2 add-img-product">
              <Image width={120} src={coverImage || notfound} />
            </div>
            <p className="img-size-details">
              **
              {lang(
                "Images should be all size accepted for best view in thumbnail. You can select only (.gif, .png, .jpeg, .jpg) format files upto 5 MB file size"
              )}
              ..!!!
            </p>
          </Col>
          <Col span={24} md={24}>
            <Form.Item
              className="upload_wrap img-uploaded"
              name={"images"}
              label={lang("Upload Gallery Image")}
              // rules={[
              //   ({ getFieldValue }) => ({
              //     validator(_, value) {
              //       if (
              //         true &&
              //         (!value || value.length === 0)
              //       ) {
              //         return Promise.reject(
              //           lang(
              //             `Please Select Service Image`
              //           )
              //         );
              //       }
              //       return Promise.resolve();
              //     },
              //   }),
              // ]}
              getValueFromEvent={(event) => {
                return event.map((item) => item.thumbUrl).join(",");
              }}
            >
              <MultipleImageUpload
                maxCount={maxSrcImgUpload}
                value={image}
                data={image}
                fileType={FileType}
                imageType={"category"}
                btnName={"Image"}
                onChange={(data) => handleImage(data)}
              />
            </Form.Item>
            <p className="img-size-details">
              **
              {lang(
                `${lang("Images should be all size accepted for best view in gallery image. You can select only")} ${maxSrcImgUpload} ${lang("images (.gif, .png, .jpeg, .jpg) format files upto 5 MB file size")}`
              )}
              ..!!!
            </p>
          </Col>
          <Col span={24} md={24}>
            {serviceAttributes?.length ? (
              <div>
                <h5 className="new-attribute-tag">{lang("Attributes")}</h5>
              </div>
            ) : (
              ""
            )}
          </Col>
          <Col span={24} md={24}>
              <Row gutter={[12, 12]}>
            {serviceAttributes?.length
              ? serviceAttributes?.map((attribute) => (
                  <Col span={24} md={12} key={attribute?._id}>
                    {attribute?.type === "radio" ? (
                      <Form.Item
                        name={attribute?.name}
                        label={showName(attribute)}
                        rules={[
                          {
                            required: attribute?.is_required,
                            message: lang(`Please select ${showName(attribute)}!`),
                          },
                        ]}
                        valuePropName="checked"
                      >
                      {attribute?.options?.map((option) => (
                          <Checkbox
                            checked={
                              form.getFieldValue(attribute?.name) ===
                              option?.name
                            }
                            onChange={(e) => {
                              if (e.target.checked) {
                                form.setFieldValue(
                                  attribute?.name,
                                  option?.name
                                );
                              } else {
                                form.setFieldValue(attribute?.name, null);
                              }
                            }}
                          >
                            {showName(option)}
                          </Checkbox>
                        ))}
                      </Form.Item>
                    ) : attribute?.type === "boolean" ? (
                      <Form.Item
                      name={attribute?.name}
                      label={showName(attribute)}
                      rules={[
                        {
                          required: attribute?.is_required,
                          message: lang(`Please select ${showName(attribute)}!`),
                        },
                      ]}
                      valuePropName="checked"
                    >
                      <Checkbox
                         checked={form.getFieldValue(attribute?.name) === true}
                         onChange={(e) => {
                           if (e.target.checked) {
                             form.setFieldValue(attribute?.name, true);
                           } else {
                             form.setFieldValue(attribute?.name, null);
                           }
                         }}
                      >
                        {lang("Yes")}
                      </Checkbox>
                      <Checkbox
                        checked={form.getFieldValue(attribute?.name) === false}
                        onChange={(e) => {
                          if (e.target.checked) {
                            form.setFieldValue(attribute?.name, false);
                          } else {
                            form.setFieldValue(attribute?.name, null);
                          }
                        }}
                      >
                        {lang("No")}
                      </Checkbox>
                    </Form.Item>
                    )
                     : attribute?.type === "checkbox" ? (
                      <Form.Item
                        name={attribute?.name}
                        label={showName(attribute)}
                        rules={[
                          {
                            required: attribute?.is_required,
                            message: lang(`Please select ${showName(attribute)}!`),
                          },
                        ]}
                      >
                        <Checkbox.Group className="checkbox-group">
                          {attribute?.options?.length && attribute?.options?.map((option) => (
                            <Checkbox
                              value={option?.name}
                              key={option?.name}
                              label={showName(option)}
                            >
                              {showName(option)}
                            </Checkbox>
                          ))}
                        </Checkbox.Group>
                      </Form.Item>
                    ) : attribute?.type === "color" ? (
                      <Form.Item
                        name={attribute?.name}
                        label={showName(attribute)}
                        rules={[
                          {
                            required: attribute?.is_required,
                            message: lang(`Please select ${showName(attribute)}!`),
                          },
                        ]}
                        valuePropName="checked"
                      >
                        {attribute?.options?.map((option) => (
                          <Checkbox
                            checked={
                              form.getFieldValue(attribute?.name) ===
                              option?.name
                            }
                            onChange={(e) => {
                              if (e.target.checked) {
                                form.setFieldValue(
                                  attribute?.name,
                                  option?.name
                                );
                              } else {
                                form.setFieldValue(attribute?.name, null);
                              }
                            }}
                          >
                            {showName(option)}
                            <ColorCircleComponent
                                color={option?.name ? option?.name : ""}
                              />
                          </Checkbox>
                        ))}
                      </Form.Item>
                    ) : attribute?.type === "dropdown" ? (
                      <Form.Item
                        name={attribute?.name}
                        label={showName(attribute)}
                        rules={[
                          {
                            required: attribute?.is_required,
                            message: lang(`Please select ${showName(attribute)}!`),
                          },
                        ]}
                      >
                        <Select
                          className="form-select"
                          placeholder={`Select ${showName(attribute)}`}
                          getPopupContainer={(triggerNode) => triggerNode.parentNode}
                        >
                          {attribute?.options?.map((option) => (
                            <Select.Option
                              value={option?.name}
                              key={option?.name}
                            >
                              {showName(option)}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    ) : attribute?.type === "file" ? (
                      <Form.Item
                        name={attribute?.name}
                        label={showName(attribute)}
                        rules={[
                          {
                            required: attribute?.is_required,
                            message: lang(`Please select ${showName(attribute)}!`),
                          },
                        ]}
                      >
                        <SingleImageUpload
                          fileType={FileType}
                          imageType={"image"}
                          btnName={"Image"}
                          isDimension={false}
                        />
                      </Form.Item>
                    ) : attribute?.type === "button" ? (
                      <Form.Item className="input-mb-o" name={attribute?.name}>
                        <Checkbox
                          valuePropName="checked"
                          checked={!!showInfoCol[attribute?.name]?.show}
                          onChange={(e) =>
                            handleInfoCol(attribute?.name, e.target.checked)
                          }
                          value={showInfoCol[attribute?.name]?.show}
                        >
                          {showName(attribute)}
                        </Checkbox>

                        {showInfoCol[attribute?.name]?.show && (
                          <Col span={12} md={24}>
                            <Form.Item className="input-mb-o">
                              <Input
                                placeholder="Enter Info"
                                value={showInfoCol[attribute?.name]?.info || ""}
                                onChange={(e) =>
                                  setShowInfoCol((prev) => ({
                                    ...prev,
                                    [attribute?.name]: {
                                      ...prev[attribute?.name],
                                      info: e.target.value,
                                    },
                                  }))
                                }
                              />
                            </Form.Item>
                          </Col>
                        )}
                      </Form.Item>
                    ) : attribute?.type === "inputNumber" ? (
                      <Form.Item
                        name={attribute?.name}
                        label={showName(attribute)}
                        rules={[
                          {
                            required: attribute?.is_required,
                            message: lang(`Please select ${showName(attribute)}!`),
                          },
                        ]}
                      >
                        <InputNumber
                          className="number-inputs"
                          placeholder={`Enter ${showName(attribute)}`}
                        />
                      </Form.Item>
                    ) : attribute?.type === "textbox" ? (
                      <Form.Item
                        name={attribute?.name}
                        label={showName(attribute)}
                        rules={[
                          {
                            required: attribute?.is_required,
                            message: lang(`Please select ${showName(attribute)}!`),
                          },
                        ]}
                      >
                        <Input placeholder={`Enter ${showName(attribute)}`} />
                      </Form.Item>
                    ) : attribute?.type === "calendar" ? (
                      <Form.Item
                        name={attribute?.name}
                        label={showName(attribute)}
                        rules={[
                          {
                            required: attribute?.is_required,
                            message: lang(`Please select ${showName(attribute)}!`),
                          },
                        ]}
                      >
                        <DatePicker format={"DD-MM-YYYY"} />
                      </Form.Item>
                    ) :
                    attribute?.type === "package" ? (
                      <div>
                        
                        {attribute?.options?.map((option) => (
                          <Form.Item
                          
                            key={option?._id}
                            label={`${showName(attribute)} (${showName(option)})`}
                            name={option?.name}
                            rules={[
                              {
                                required: attribute?.is_required,
                                message: lang(`Please select ${showName(attribute)}!`),
                              },
                            ]}
                          >

                            <Select
                              mode="tags"
                              style={{ width: '100%' }}
                              placeholder={`Select ${showName(option)}`}
                            
                            >
                              {option?.tags?.map((tag) => (
                                <Select.Option key={tag} value={tag}>
                                  {tag}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        ))}
                      </div>


                    ) :
                     null}
                  </Col>
                ))
              : ""}
              </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddForm;
