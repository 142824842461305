import {
  Button,
  Card,
  Col,
  Input,
  Modal,
  Row,
  Table,
  Tabs,
  Tooltip,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import apiPath from "../../constants/apiPath";
import { AppStateContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";

import Services from "./Services";
import Packages from "./Packages";
import { useAuthContext } from "../../context/AuthContext";
const { TabPane } = Tabs;



function Index() {
  const { setPageHeading } = useContext(AppStateContext);
  const [selectedTab, setSelectedTab] = useState("requested");
  const {  selectedCategory } = useAuthContext();


  useEffect(() => {
    setPageHeading(lang("Quotation Management"));
  }, []);

  const handleTabChange = (status) => {
    setSelectedTab(status);
  };

  return (
    <>
      <div className="tabled quoteManagement">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
              <Tabs
                className="main_tabs"
                onTabClick={handleTabChange}
                activeKey={selectedTab}
                tabBarStyle={{ color: "green" }}
              >
                <TabPane tab={lang("Services Quote")} key={"requested"}>
                <Services />
                </TabPane>

                <TabPane tab={lang("Packages Quote")} key={"package"}>
                <Packages />
                </TabPane>
              </Tabs>
            
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Index;
