import { Col, Form, Input, Layout, Row, Typography, Button, Modal, } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";

import signinLogo from "../../assets/images/Logo.svg";
import editlogo from "../../assets/images/icon/edit-icon.png";
import signinbg from "../../assets/images/otp-frame.png";
import apiPath from "../../constants/apiPath";
import useRequest from "../../hooks/useRequest";
import { Severty, ShowToast } from "../../helper/toast";
import { PhoneNumberField } from "../../components/InputField";
import { InputOTP } from "antd-input-otp";
import lang from "../../helper/langHelper";
import { AuthContext } from "../../context/AuthContext";

import { useNavigate } from "react-router";

const { Title } = Typography;
const { Content } = Layout;

const Otp = ({
  data,
  loading,
}) => {
  const { request } = useRequest();
  const [form] = Form.useForm();
  const { setIsLoggedIn, setUserProfile , setRefreshUserProfile } = useContext(AuthContext);

  const { mobile_number: mobile_number, country_code, email ,category_id} = data;
  const [resendTimer, setResendTimer] = useState(0);
  const [visible, setVisible] = useState(false);
  const [mobileNumber, setMobileNumber] = useState({});
  const navigate = useNavigate()


  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setTimeout(() => {
        setResendTimer(resendTimer - 1);
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [resendTimer]);

  const handleResend = () => {
    if (resendTimer > 0) return;
    setResendTimer(60); // Reset the timer to 60 seconds

    const payload = {
      ...data,
      type: "Vendor",
      use_for: "SignUp",
      email: email,
      country_code : country_code ,
      mobile_number : mobile_number,
      category_id : category_id,
      device_type : "web"

    };

   

    request({
      url: apiPath.sendOtp,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
        }
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR);
      },
    });
  };


  

  const handleSubmitOtp = (otp) => {
    let combinedOtp = otp 
    console.log(combinedOtp,otp, "combinedOtp");

    if (combinedOtp?.length !== 4) {
      ShowToast("Please enter the complete OTP", Severty.ERROR);
      return;
    }
    combinedOtp = otp ? otp?.join("") : "";
  
    const payload = {
      ...data,
      otp : combinedOtp,
      type: "Vendor",
      use_for: "SignUp",
      device_type : "web",
      country_code : country_code ,
      mobile_number : mobile_number,
      category_id : category_id,
    };
    request({
      url: apiPath.verifyOTP,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        if (!data?.status) {
          ShowToast(data.message, Severty.ERROR);
          return;
        } else {
             console.log(data, "userdata...")
          ShowToast(data.message, Severty.SUCCESS);
          setIsLoggedIn(true);
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("userProfile", JSON.stringify(data?.data?.user));
          setUserProfile(data?.data?.user);
          setRefreshUserProfile(prev => !prev)
          setTimeout(() => navigate("/"), 200);
        }
      },
      onError: (err) => {

        ShowToast(err?.response?.data?.message, Severty.ERROR);
      },
    });
  };
  

  return (
    <>
      <Layout className="layout-default layout-signin">
        <Content className="signin-otp">
          <Row>
            <Col span={24} md={12} className="signin-bg-otp ">
              <div className="signin_img-otp">
                <img src={signinbg} />
              </div>
            </Col>
            <Col span={24} md={12} className="signin-box-otpmain00">
              <div className="signin-box-otp  ">
                <div className="signup-logo-otp">
                  <img src={signinLogo} alt="" />
                </div>
                    <div className="signup-form-otp">
                      <Title className="mb-15">Verification code</Title>
                      <Title className="font-regular text-muted" level={5}>
                        We have sent the code verification to Email
                      </Title>
                      {/* <Title className="number-otp-vr"></Title> */}
                      <Form form={form} className="row-col">
                        <div className="otp-box-check">
                          <Form.Item
                            name="otp"
                            rules={[
                              {
                                required: true,
                                message: lang("Please enter the OTP"),
                              },
                        
                            ]}
                          >
                            <InputOTP
                              className="otp-of-type"
                              autoSubmit={handleSubmitOtp}
                              length={4}
                              inputType="numeric"
                            />
                          </Form.Item>
                        </div>
                        <div className="forgot-pass">
                          {resendTimer > 0 ? (
                            <Form.Item
                              name="remember"
                              className="aligin-center"
                            >
                              <span onClick={handleResend}>
                                Resend SMS in {resendTimer}
                              </span>
                            </Form.Item>
                          ) : (
                            <Form.Item
                              name="remember"
                              className="aligin-center"
                            >
                              <Button
                                className="primary_btn btnStyle w-100"
                                onClick={handleResend}
                              >
                                Resend OTP
                              </Button>
                            </Form.Item>
                          )}
                        </div>
                      </Form>
                    </div>
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>

     
    </>
  );
};

export default Otp;
